(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-setup-marketplace-listener.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-setup-marketplace-listener.js');
"use strict";


const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const {
  updateMarketplaceWallet,
  removeMarketplaceWallet
} = svs.components.tipsen.wallet.actions;
const {
  DRAWSTATE_FINALIZED
} = svs.components.marketplaceData.constants;
const {
  PARAM_MARKETPLACE_GAME_ID,
  PARAM_DRAWNUMBER,
  PARAM_PRODUCT_ID
} = svs.components.sport.tipsenShared;
const {
  useDispatch
} = ReactRedux;
const {
  useEffect,
  useCallback
} = React;
const {
  useHistory,
  useLocation
} = ReactRouterDOM;
const useSetUpMarketplaceBalanceListener = async function () {
  let skip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const listener = useCallback(activatedDraw => {
    if (activatedDraw && activatedDraw.data && activatedDraw.state.status !== DRAWSTATE_FINALIZED) {
      dispatch(updateMarketplaceWallet({
        activatedDrawId: activatedDraw.data.activatedDrawId,
        balance: activatedDraw.data.balance,
        soldShares: activatedDraw.data.soldShares,
        commissionPercent: activatedDraw.data.commissionPercent
      }));
    } else if (activatedDraw !== null && activatedDraw !== void 0 && activatedDraw.data.activatedDrawId && (activatedDraw === null || activatedDraw === void 0 ? void 0 : activatedDraw.state.status) === DRAWSTATE_FINALIZED) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(PARAM_MARKETPLACE_GAME_ID);
      queryParams.delete(PARAM_DRAWNUMBER);
      queryParams.delete(PARAM_PRODUCT_ID);
      history.replace({
        search: queryParams.toString()
      });
      dispatch(removeMarketplaceWallet());
    }
  }, [dispatch, history, location]);
  useEffect(() => {
    if (skip) {
      return;
    }
    getAccountBalance.on('change', listener);
    return () => {
      getAccountBalance.off('change', listener);
    };
  }, [listener, skip]);
};
setGlobal('svs.components.tipsen.couponId.useSetUpMarketplaceBalanceListener', useSetUpMarketplaceBalanceListener);

 })(window);